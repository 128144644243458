<template>
  <ValidationObserver slim ref="observer" v-slot="{ valid }">
    <EuiContainer style="height: calc(100vh - 180px);">
    <form class="es-setting">
      <!-- **TOOLBAR** -->
      <EuiGrid gutters>
        <EuiGridRow>
          <EuiGridColumn>
            <div class="flex items-center">
              <EuiButton iconOnly size="large" variant="text" @click="cancel()">
                <EuiIcon name="chevron_left" color="primary"/>
              </EuiButton>
              <EuiHeading :level="1" bold>{{ $t('settings.users.create.title') }}</EuiHeading>
            </div>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiBlock :title="$i18n.t('settings.users.create.userInfo.title')">
              <div class="eui-u-flex eui-u-mb-6">
                <div class="eui-u-flex-1">
                  <ValidationProvider :name="$t('settings.users.create.firstName.label')" rules="required" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="eui-u-mb-2"
                                  :label="$t('settings.users.create.firstName.label')"
                                  type="text"
                                  v-model="user.firstname"
                                  :valid="valid"
                                  :invalid="errors.length > 0"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('settings.users.create.lastName.label')" rules="required" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="eui-u-mb-2"
                                  :label="$t('settings.users.create.lastName.label')"
                                  type="text"
                                  v-model="user.lastname"
                                  :valid="valid"
                                  :invalid="errors.length > 0"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('settings.users.create.mobilePhone.label')" rules="required|phone" v-slot="{ errors, valid, invalid }" v-if="options && options.signerCreationFormDefineMobilePhone && options.signerCreationFormDefineMobilePhone.value === '1'">
                    <EuiTextField adaptSize
                                  class="eui-u-mb-2"
                                  :label="$t('settings.users.create.mobilePhone.label')"
                                  type="text"
                                  v-model="user.mobilePhone"
                                  :valid="valid"
                                  :invalid="errors.length > 0"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('settings.users.create.email.label')" rules="required|email" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="eui-u-mb-2"
                                  :label="$t('settings.users.create.email.label')"
                                  type="text"
                                  v-model="user.email"
                                  :valid="valid"
                                  :invalid="errors.length > 0"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <EuiCheckbox v-model="user.microsoftCredential">
                    {{ $t('settings.users.microsoft') }}
                  </EuiCheckBox>
                </div>
                <div class="eui-u-flex-1 eui-u-ml-5">
                  <ValidationProvider :name="$t('settings.users.create.group.label')" rules="required" v-slot="{ errors }">
                    <EuiTextField class="eui-u-mb-2" adaptSize>
                      <EuiLabel>{{ $t('settings.users.create.group.label') }}</EuiLabel>
                      <select class="eui-a-input -adaptSize -noIcon eui-u-px-2" v-model="group" required >
                        <option value="" selected disabled hidden>---</option>
                        <option v-for="option in groups" :value="option.id" :key="option.id">{{ option.name }}</option>
                      </select>
                      <p v-if="errors[0]" class="eui-u-my-4 eui-u-text-base eui-u-font-bold eui-u-text-red">
                        {{ errors[0] }}
                      </p>
                    </EuiTextField>
                  </ValidationProvider>
                </div>
              </div>
              <template v-if="!user.microsoftCredential">
                <EuiHeading :level="4" bold>{{ $t('settings.users.create.credentials.title') }}</EuiHeading>
                <div class="w-1/2">
                  <ValidationProvider :name="$t('settings.users.create.login.label')" rules="required" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="eui-u-mb-2"
                                  :label="$t('settings.users.create.login.label')"
                                  type="text"
                                  v-model="user.login"
                                  :valid="valid"
                                  :invalid="errors.length > 0"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('profile.label.newPassword')" rules="required|profilePassword" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('profile.label.newPassword')"
                                  :type="passwordFieldType"
                                  v-model.trim="user.password"
                                  :valid="valid && validatePassword"
                                  :invalid="errors.length > 0 || !validatePassword"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"
                                  @keyup="passwordStrengthValidation"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('profile.label.confirmPassword')" rules="required|profilePassword" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('profile.label.confirmPassword')"
                                  :type="passwordFieldType"
                                  v-model.trim="confirmPassword"
                                  :valid="valid && validatePassword"
                                  :invalid="errors.length > 0 || !validatePassword"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <div class="flex">
                    <div class="flex-1 mt-2">
                      <div class="mb-2">
                        <span class="es-form-validation-indicator" :class="{ 'is-valid' : passwordValidRequirement.is8long }"/>
                        {{ $t('settings.users.create.password.at.least.length') }}
                      </div>
                      <div class="mb-2">
                        <span class="es-form-validation-indicator" :class="{ 'is-valid' : passwordValidRequirement.hasCaps }"/>
                        {{ $t('settings.users.create.password.at.least.caps') }}
                      </div>
                      <div class="mb-2">
                    <span class="es-form-validation-indicator"
                          :class="{ 'is-valid' : passwordValidRequirement.hasNumber }"/>
                        {{ $t('settings.users.create.password.at.least.number') }}
                      </div>
                      <div class="mb-2">
                    <span class="es-form-validation-indicator"
                          :class="{ 'is-valid' : passwordValidRequirement.hasSpecialChar }"/>
                        {{ $t('settings.users.create.password.at.least.special.char') }}
                      </div>
                    </div>
                    <div>
                      <EuiButton class="eui-u-p-2" color="primary" variant="text" @click.prevent="switchPasswordVisibility">
                        <template v-if="passwordFieldType === 'password'">
                          {{ $t('settings.users.create.password.show') }}
                        </template>
                        <template v-else>
                          {{ $t('settings.users.create.password.hide') }}
                        </template>
                      </EuiButton>
                    </div>
                  </div>
                  <p :style="{ visibility: isPasswordValid !== undefined && !isPasswordValid ? 'visible' : 'hidden' }" class="text-alert">{{ $t('settings.users.create.messages.create.validation.error.passwordMismatch') }}</p>
                </div>
              </template>
            </EuiBlock>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow v-if="selectedGroup">
          <EuiGridColumn>
            <EuiBlock :title="$i18n.t('settings.users.create.userPermissions.title') + selectedGroup.name">
              <EuiTable>
                <EuiTableHeader>
                  <EuiTableRow class="cursor-default">
                    <EuiTableHead> </EuiTableHead>
                    <EuiTableHead>{{ $t('settings.users.create.permissions.table.headers.createUpdate') }}</EuiTableHead>
                    <EuiTableHead>{{ $t('settings.users.create.permissions.table.headers.list') }}</EuiTableHead>
                    <EuiTableHead>{{ $t('settings.users.create.permissions.table.headers.other') }}</EuiTableHead>
                  </EuiTableRow>
                </EuiTableHeader>
                <EuiTableBody>
                  <EuiTableRow class="hover:bg-transparent cursor-default">
                    <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.create.permissions.table.headers.paraphers') }}</EuiTableHead>
                  </EuiTableRow>
                  <EuiTableRow class="hover:bg-transparent cursor-default">
                    <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.templates') }}</EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon v-if="selectedGroup.hasWriteModelPermission === '1'" size="mini" name="check" color="success" outlined/>
                    </EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon v-if="selectedGroup.hasReadModelPermission === '1'" size="mini" name="check" color="success" outlined/>
                    </EuiTableCell>
                    <EuiTableCell>
                    </EuiTableCell>
                  </EuiTableRow>
                  <EuiTableRow class="hover:bg-transparent cursor-default">
                    <EuiTableCell class="align-middle">{{ $t('settings.groups.create.permissions.table.headers.sharedTemplates') }}</EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon v-if="selectedGroup.hasWholeSignbooksEditingPermission === '1'" size="mini" name="check" color="success" outlined/>
                    </EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon v-if="selectedGroup.hasWholeSignbooksListingPermission === '1'" size="mini" name="check" color="success" outlined/>
                    </EuiTableCell>
                    <EuiTableCell>
                    </EuiTableCell>
                  </EuiTableRow>
                  <EuiTableRow class="hover:bg-transparent cursor-default">
                    <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.create.permissions.table.headers.users') }}</EuiTableHead>
                  </EuiTableRow>
                  <EuiTableRow class="hover:bg-transparent cursor-default">
                    <EuiTableCell>{{ $t('settings.groups.create.permissions.table.headers.userRights') }}</EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon v-if="selectedGroup.hasEditUserPermission === '1'" size="mini" name="check" color="success" outlined/>
                    </EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon size="mini" name="check" color="success" outlined/>
                    </EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon v-if="selectedGroup.hasAccessRulesPermission === '1'" size="mini" name="check" color="success" outlined/>
                      <div v-if="selectedGroup.hasAccessRulesPermission === '1'" class="ml-2 inline-block">{{ $t('settings.groups.create.permissions.table.other.managePermissions') }}</div>
                    </EuiTableCell>
                  </EuiTableRow>
                  <EuiTableRow class="hover:bg-transparent cursor-default">
                    <EuiTableCell class="align-middle">
                      {{ $t('settings.groups.edit.permissions.table.headers.manageSocialSignatureAuthorizations') }}
                    </EuiTableCell>
                    <EuiTableCell>
                    </EuiTableCell>
                    <EuiTableCell>
                    </EuiTableCell>
                    <EuiTableCell>
                      <EuiIcon v-if="selectedGroup.hasSocialSignerTriggerPermission === '1'" size="mini" name="check" color="success" outlined/>
                      <div v-if="selectedGroup.hasSocialSignerTriggerPermission === '1'" class="ml-2 inline-block">{{ $t('settings.groups.create.permissions.table.other.manageSocialSignatureAuthorizations') }}</div>
                    </EuiTableCell>
                  </EuiTableRow>
                </EuiTableBody>
              </EuiTable>
            </EuiBlock>
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </form>
    </EuiContainer>
    <EuiBottomBar>
      <EuiButton @click.prevent="cancel">{{ $t('button.cancel') }}</EuiButton>
      <EuiButton variant="raised" color="primary" @click="createUser()" :disabled="!valid || !validatePassword">{{ $t('button.save') }}</EuiButton>
    </EuiBottomBar>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DOMpurify from 'dompurify';

export default {
  /**
   * The name of the page.
   */
  name: 'CreateUser',

  /**
   * The data the page can use.
   *
   * @returns {Object} The data.
   */
  data() {
    return {
      isPasswordValid: undefined,
      group: undefined,
      confirmPassword: '',
      passwordValidStrength: false,
      passwordFieldType: 'password',
      searchParams: {
        orders: [{ fieldName: 'name', orderBy: 'DESC' }],
        params: [],
        offset: 0,
        limit: 10,
      },
      passwordValidRequirement: {
        is8long: false,
        hasCaps: false,
        hasNumber: false,
        hasSpecialChar: false,
      },
      user: {
        firstname: '',
        lastname: '',
        email: '',
        microsoftCredential: false,
        town: '',
        postalCode: '',
        address: '',
        mobilePhone: '',
        entityType: 'person',
        service: '',
        function: '',
        logo: '',
        login: '',
        password: '',
      },
      groups: null,
      selectedGroup: null,
    };
  },

  /**
   * The computed properties for the component.
   */
  computed: {
    ...mapGetters({
      options: 'ModuleEdocSign/auth/options',
    }),
    validatePassword() {
      return this.user.password === this.confirmPassword;
    },
    userGroup() {
      return this.group;
    },
  },

  /**
   * Available watchers for this page.
   */
  watch: {
    userGroup(value) {
      this.findById(value).then((response) => {
        this.selectedGroup = response;
      });
    },
  },

  /**
   * The methods the page can use.
   */
  methods: {
    ...mapActions({
      create: 'ModuleEdocSign/settingsUsers/create',
      findAll: 'ModuleEdocSign/settingsGroups/findAll',
      findById: 'ModuleEdocSign/settingsGroups/findById',
      addUserToGroup: 'ModuleEdocSign/settingsGroups/addUserToGroup',
      pushAlert: 'application/pushAlert',
    }),
    switchPasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    cancel() {
      this.$router.push({ name: 'settings.users.index' });
    },

    /**
     * Password strength validation
     */
    passwordStrengthValidation() {
      this.passwordValidRequirement.is8long = new RegExp('^(?=.{8,})').test(this.user.password);
      this.passwordValidRequirement.hasNumber = new RegExp('^(?=.*[0-9])').test(this.user.password);
      this.passwordValidRequirement.hasCaps = new RegExp('^(?=.*[A-Z])').test(this.user.password);
      this.passwordValidRequirement.hasSpecialChar = new RegExp('^(?=.*[!@#$%^&*])').test(this.user.password);
      let errorCount = 0;
      Object.keys(this.passwordValidRequirement).forEach((key) => {
        if (!this.passwordValidRequirement[key]) {
          errorCount += 1;
        }
      });
      if (errorCount === 0) {
        this.passwordValidStrength = true;
      }
    },
    fetchGroups() {
      return this.findAll(this.searchParams).then((response) => {
        this.groups = response.groups;
        this.selectedGroup = this.groups.find((group) => group.isDefault === '1');
      });
    },
    createUser() {
      this.user.firstname = DOMpurify.sanitize(this.user.firstname);
      this.user.lastname = DOMpurify.sanitize(this.user.lastname);
      if (this.user.microsoftCredential) {
        this.user.login = this.$uuid.v4();
        this.user.password = `PwD_${this.$uuid.v4()}`;
      }
      this.create(this.user).then((response) =>
        this.addUserToGroup({ group: { id: this.selectedGroup.id }, user: { userId: response.userId } }))
        .then(() => {
          this.cancel();
        });
    },
  },

  /**
   * This method will be fired once the application has been mounted.
   */
  mounted() {
    this.fetchGroups();
  },
};
</script>
